import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ExternalUserStateMapActions, InternalUserStateMapActions } from './status-map.actions';
import * as statusMapSelectors from './status-map.selectors';
import { ProcessStatus } from '../../shared/enums/replenishment-process/process-status';
import { ProcessType } from '../../shared/enums/replenishment-process/process-type.enum';
import { StatusMapEssentialInterface } from '../../shared/interfaces/replenishment-process/status-map.interface';
import { State } from '../index';

@Injectable()
export class StatusMapFacade {

  constructor(private store: Store<State>) {}

  public loadExternalUserStatuses(): void {
    this.store.dispatch(ExternalUserStateMapActions.load());
  }

  public loadInternalUserStatuses(): void {
    this.store.dispatch(InternalUserStateMapActions.load());
  }

  public getExternalUserNextStatuses(currentStatus: ProcessStatus, replenishmentType: ProcessType): Observable<Array<ProcessStatus>> {
    return this.store.select(statusMapSelectors.getExternalUserNextStatuses({ currentStatus, replenishmentType }));
  }

  // check tableRowForbiddenStatusChanges to disable status changes in the table row
  public getExternalTableRowNextStatuses(currentStatus: ProcessStatus, replenishmentType: ProcessType): Observable<Array<ProcessStatus>> {
    return this.store.select(statusMapSelectors.getExternalTableRowNextStatuses({ currentStatus, replenishmentType }));
  }

  public getExternalUserCommonStatuses(allCurrentStatuses: Array<StatusMapEssentialInterface>): Observable<Array<ProcessStatus>> {
    return this.store.select(statusMapSelectors.getExternalCommonNextStatuses(allCurrentStatuses));
  }

  // check tableRowForbiddenStatusChanges to disable status changes in the table row
  public getExternalCommonTableRowNextStatuses(allCurrentStatuses: Array<StatusMapEssentialInterface>): Observable<Array<ProcessStatus>> {
    return this.store.select(statusMapSelectors.getExternalCommonTableRowNextStatuses(allCurrentStatuses));
  }

  public getInternalUserCommonStatuses(allCurrentStatuses: Array<StatusMapEssentialInterface>): Observable<Array<ProcessStatus>> {
    return this.store.select(statusMapSelectors.getInternalCommonNextStatuses(allCurrentStatuses));
  }

  // check tableRowForbiddenStatusChanges to disable status changes in the table row
  public getInternalCommonTableRowStatuses(allCurrentStatuses: Array<StatusMapEssentialInterface>): Observable<Array<ProcessStatus>> {
    return this.store.select(statusMapSelectors.getInternalCommonTableRowNextStatuses(allCurrentStatuses));
  }

  public getInternalUserNextStatuses(currentStatus: ProcessStatus, replenishmentType: ProcessType): Observable<Array<ProcessStatus>> {
    return this.store.select(statusMapSelectors.getInternalUserNextStatuses({ currentStatus, replenishmentType }));
  }

  // check tableRowForbiddenStatusChanges to disable status changes in the table row
  public getInternalTableRowNextStatuses(currentStatus: ProcessStatus, replenishmentType: ProcessType): Observable<Array<ProcessStatus>> {
    return this.store.select(statusMapSelectors.getInternalTableRowNextStatuses({ currentStatus, replenishmentType }));
  }

  public getNextStatuses(
    isExternalUser: boolean,
    currentStatus: ProcessStatus,
    replenishmentType: ProcessType
  ): Observable<Array<ProcessStatus>> {
    if (isExternalUser) {
      return this.getExternalUserNextStatuses(currentStatus, replenishmentType);
    }
    return this.getInternalUserNextStatuses(currentStatus, replenishmentType);
  }
}
