import { Injectable } from '@angular/core';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { DateTime } from 'luxon';
import { map, Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ProcessTypeChangeReason } from '../../../shared/enums/replenishment-process/process-type-change-reasons.enum';
import { CostEstimateCreateBody } from '../../../shared/interfaces/cost-estimate/cost-estimate-create-body.interface';
import { CostEstimateCreateResponse } from '../../../shared/interfaces/cost-estimate/cost-estimate-create-response.interface';
import { DeliveryNoteCreateBody } from '../../../shared/interfaces/delivery-note/delivery-note';
import { ErpOrder } from '../../../shared/interfaces/erp-orders/erp-order';
import { PagingResponseInterface } from '../../../shared/interfaces/layout/paging/paging-response.interface';
import { ProcessTableFiltersResponse } from '../../../shared/interfaces/layout/processes-table/process-filters-dropdown-values';
import { ProformaInvoiceData } from '../../../shared/interfaces/pdfs/proforma-invoice-data';
import { ActivityLogItem } from '../../../shared/interfaces/replenishment-process/activity-log-item';
import { BulkPriceUpdateBody } from '../../../shared/interfaces/replenishment-process/bulk-price-update-body';
import {
  BulkStatusUpdateBody
} from '../../../shared/interfaces/replenishment-process/bulk-status-update-body';
import { ErpNumberUpdateBody } from '../../../shared/interfaces/replenishment-process/erp-number-update-body';
import { OrderNumberUpdateBody } from '../../../shared/interfaces/replenishment-process/order-number-update-body.interface';
import {
  ProcessTypeChangeInterface
} from '../../../shared/interfaces/replenishment-process/process-type-change.interface';
import {
  ReplenishmentProcessUpdateBody
} from '../../../shared/interfaces/replenishment-process/replenishment-process-update-body';
import {
  ReplenishmentProcessInterface
} from '../../../shared/interfaces/replenishment-process/replenishment-process.interface';

@Injectable({
  providedIn: 'root'
})
export class ReplenishmentService {

  constructor(private httpService: HttpService) { }

  public getReplenishmentsInOrder(
    filtersBase64: string,
    pageNumber: number,
    pageSize: number,
    sortColumnName: string,
    sortDirection: string): Observable<PagingResponseInterface<ReplenishmentProcessInterface>> {
    return this.httpService.request('v2/replenishments')
      .param('filters', filtersBase64)
      .param('page', pageNumber.toString())
      .param('size', pageSize.toString())
      .param('sort', `${sortColumnName},${sortDirection}`)
      .get(environment.backendUrls.REPLENISHMENT);
  }

  public getReplenishmentsCount(filtersBase64: string): Observable<number> {
    return this.httpService.request('v2/replenishments')
      .param('filters', filtersBase64)
      .param('page', '0')
      .param('size', '1')
      .get(environment.backendUrls.REPLENISHMENT)
      .pipe(map((response: PagingResponseInterface<ReplenishmentProcessInterface>) => response.totalElements));
  }

  public getProcessFiltersDropdownValues(filterString: string): Observable<ProcessTableFiltersResponse> {
    return this.httpService.request('v2/replenishments/filter-values')
      .param('filters', filterString)
      .get(environment.backendUrls.REPLENISHMENT);
  }

  public createDeliveryNote(body: DeliveryNoteCreateBody): Observable<{ noteId: string }> {
    return this.httpService.request('v1/notes')
      .body(body)
      .param('dateTime', DateTime.fromJSDate(new Date()).toISO()!)
      .post(environment.backendUrls.REPLENISHMENT);
  }

  public getWorkshopDeliveryNoteIds(): Observable<Array<string>> {
    return this.httpService.request('v2/replenishments/workshop-delivery-note-ids')
      .get(environment.backendUrls.REPLENISHMENT);
  }

  public updateProcessStatusBulk(updateBody: BulkStatusUpdateBody): Observable<void> {
    return this.httpService.request('v2/replenishments/statuses')
      .body(updateBody)
      .put(environment.backendUrls.REPLENISHMENT);
  }

  public updateReplenishmentProcess(processId: string, body: ReplenishmentProcessUpdateBody): Observable<void> {
    return this.httpService.request(`v2/replenishments/${processId}`)
      .body(body)
      .patch(environment.backendUrls.REPLENISHMENT);
  }

  public getChangeTypeReasons(): Observable<Array<ProcessTypeChangeReason>> {
    return this.httpService.request('v2/replenishments/reasons')
      .get(environment.backendUrls.REPLENISHMENT);
  }

  public updateReplenishmentProcessType(processId: string, body: ProcessTypeChangeInterface): Observable<void> {
    return this.httpService.request(`v2/replenishments/${processId}/type`)
      .body(body)
      .patch(environment.backendUrls.REPLENISHMENT);

  }

  public createCostEstimate(body: CostEstimateCreateBody): Observable<CostEstimateCreateResponse> {
    return this.httpService.request('v1/cost-estimations')
      .body(body)
      .param('userDateTime', DateTime.fromJSDate(new Date()).toISO()!)
      .post(environment.backendUrls.REPLENISHMENT);
  }

  public changeOrderNumber(body: OrderNumberUpdateBody): Observable<void> {
    return this.httpService.request('v2/replenishments/order-numbers')
      .body(body)
      .patch(environment.backendUrls.REPLENISHMENT);
  }

  public changeErpNumber(body: ErpNumberUpdateBody): Observable<void> {
    return this.httpService.request('v2/replenishments/erp-order-numbers')
      .body(body)
      .patch(environment.backendUrls.REPLENISHMENT);
  }

  public createSapOrder(rpIds: Array<string>): Observable<ErpOrder> {
    return this.httpService.request('v2/replenishments/erp-order')
      .body({ rpIds })
      .post(environment.backendUrls.REPLENISHMENT);
  }

  public getActivityLog(processId: string): Observable<Array<ActivityLogItem>> {
    return this.httpService.request(`/v2/replenishments/${processId}/activity-log`)
      .get(environment.backendUrls.REPLENISHMENT);
  }

  public updateReplenishmentProcessPriceBulk(body: BulkPriceUpdateBody): Observable<void> {
    return this.httpService.request('v2/replenishments/prices')
      .body(body)
      .patch(environment.backendUrls.REPLENISHMENT);
  }

  public createProformaInvoice(selectedWorkshopDeliveryNoteIds: Array<string>): Observable<ProformaInvoiceData> {
    const body = { deliveryNoteIds: selectedWorkshopDeliveryNoteIds };
    return this.httpService.request('v2/replenishments/proforma')
      .param('userDateTime', DateTime.fromJSDate(new Date()).toISO()!)
      .body(body)
      .patch(environment.backendUrls.REPLENISHMENT);
  }
}
