import { EntityState } from '@ngrx/entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { StatusMapState } from './status-map.reducer';
import { ProcessStatus } from '../../shared/enums/replenishment-process/process-status';
import { ProcessType } from '../../shared/enums/replenishment-process/process-type.enum';
import { StatusMapEssentialInterface, StatusMapInterface } from '../../shared/interfaces/replenishment-process/status-map.interface';
import { HelperService } from '../../shared/services/utils/helper.service';
import { getState, State } from '../index';

const getStatusState = createSelector(getState, (state: State) => state.statusMap);

const getExternalUserState = createSelector(getStatusState, (state: StatusMapState) => state.externalUserStates);
const getInternalUserState = createSelector(getStatusState, (state: StatusMapState) => state.internalUserStates);

export const getExternalUserNextStatuses =
  (currentStatus: StatusMapEssentialInterface): MemoizedSelector<State, Array<ProcessStatus>> => createSelector(
    getExternalUserState,
    (state: EntityState<StatusMapInterface>) =>
      state?.entities[`${ currentStatus.replenishmentType } - ${ currentStatus.currentStatus }`]?.statusList || []);

export const getExternalTableRowNextStatuses =
  (currentStatus: StatusMapEssentialInterface): MemoizedSelector<State, Array<ProcessStatus>> => createSelector(
    getExternalUserNextStatuses(currentStatus),
    (possibleNextStatuses: Array<ProcessStatus>) =>
      HelperService.isStatusChangeVisibleInTableRow(currentStatus.replenishmentType as ProcessType, currentStatus.currentStatus) ?
        possibleNextStatuses : []
  );

export const getInternalUserNextStatuses =
  (currentStatus: Omit<StatusMapInterface, 'statusList' | 'viewPoint'>): MemoizedSelector<State, Array<ProcessStatus>> => createSelector(
    getInternalUserState,
    (state: EntityState<StatusMapInterface>) => getNextStatuses(currentStatus, state)
  );

export const getInternalTableRowNextStatuses =
  (currentStatus: Omit<StatusMapInterface, 'statusList' | 'viewPoint'>): MemoizedSelector<State, Array<ProcessStatus>> => createSelector(
    getInternalUserNextStatuses(currentStatus),
    (possibleNextSteps: Array<ProcessStatus>) =>
      HelperService.isStatusChangeVisibleInTableRow(currentStatus.replenishmentType as ProcessType, currentStatus.currentStatus) ?
        possibleNextSteps : []
  );

export const getExternalCommonNextStatuses = (
  currentStatuses: Array<StatusMapEssentialInterface>
): MemoizedSelector<State, Array<ProcessStatus>> => createSelector(
  getExternalUserState,
  (state: EntityState<StatusMapInterface>) => {
    return handleCommonStatuses(currentStatuses, state);
  }
);

export const getExternalCommonTableRowNextStatuses =
  ( currentStatuses: Array<StatusMapEssentialInterface>): MemoizedSelector<State, Array<ProcessStatus>> => createSelector(
    getExternalUserState,
    (state: EntityState<StatusMapInterface>) => {
      const filteredVisibleStatuses = currentStatuses.filter(
        (currentStatus: StatusMapEssentialInterface ) =>
          HelperService.isStatusChangeVisibleInTableRow(
            currentStatus.replenishmentType as ProcessType,
            currentStatus.currentStatus));
      return handleCommonStatuses(filteredVisibleStatuses, state);
    });

export const getInternalCommonNextStatuses = (
  currentStatuses: Array<StatusMapEssentialInterface>
): MemoizedSelector<State, Array<ProcessStatus>> => createSelector(
  getInternalUserState,
  (state: EntityState<StatusMapInterface>) => {
    return handleCommonStatuses(currentStatuses, state);
  }
);

export const getInternalCommonTableRowNextStatuses =
  ( currentStatuses: Array<StatusMapEssentialInterface>): MemoizedSelector<State, Array<ProcessStatus>> => createSelector(
    getInternalUserState,
    (state: EntityState<StatusMapInterface>) => {
      const filteredVisibleStatuses = currentStatuses.filter(
        (currentStatus: StatusMapEssentialInterface ) =>
          HelperService.isStatusChangeVisibleInTableRow(
            currentStatus.replenishmentType as ProcessType,
            currentStatus.currentStatus));
      return handleCommonStatuses(filteredVisibleStatuses, state);
    });

const getNextStatuses =
  (statusData: Omit<StatusMapInterface, 'statusList' | 'viewPoint'>, state: EntityState<StatusMapInterface>): Array<ProcessStatus> =>
    state?.entities[`${statusData.replenishmentType} - ${statusData.currentStatus}`]?.statusList || [];

const handleCommonStatuses =
  (currentStatuses: Array<StatusMapEssentialInterface>, state: EntityState<StatusMapInterface>): Array<ProcessStatus> => {
    if (currentStatuses.length === 0) {
      return [];
    }
    let firstStatuses = getNextStatuses(currentStatuses[0], state);
    for (let i = 1; i < currentStatuses.length; i++) {
      const nextStatuses = getNextStatuses(currentStatuses[i], state);
      firstStatuses = firstStatuses.filter(status => nextStatuses.includes(status));
    }
    return firstStatuses;
  };
